<template>
    <div class="deviceStrategySetClass">
        <el-main style="padding: 0px">
            <el-form :model="form" ref="strategyForm">
                <el-form-item label="策略状态" prop="type">
                    <el-checkbox-group v-model="enableArr.newValue">
                        <br/>
                        <el-checkbox label="1" name="type">保温策略</el-checkbox>
                        <el-checkbox label="2" name="type">定时补电</el-checkbox>
                        <el-checkbox label="3" name="type">例行启动</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="备注：" prop="remark" :rules="{required: true, message: '请填写操作备注', trigger: 'blur'}">
                    <el-input
                        class="remark"
                        v-model="form.remark"
                        type="textarea"
                        maxlength="300"
                        autocomplete="off"
                        placeholder="请填写操作备注">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button size="mini" type="primary" @click="sendCmd" :disabled="disableBtn">设置</el-button>
                </el-form-item>
            </el-form>
        </el-main>
    </div>
</template>
<script>
    import websocket from "@/utils/websocket";
    import loading from "@/utils/cmdLoading";
    import message from "@/utils/message";
    import {getToken} from "@/api/auth";
    import {deviceOpStatusOperate} from "@/api/station/deviceInfoApi";
    export default {
        name: "device-strategy-set",
        props: {

            deviceId: {

                type: Number
            },
            deviceNumber: {

                type: String
            }
        },
        data() {

            return {

                enableArr: {

                    oldValue: [],
                    newValue: []
                },
                form: {

                    remark: '',
                },
                labelPosition: 'left',
                disableBtn: false,
                topic: "/user/" + getToken() + "/deviceOpsStatusSet",
                cmdLoading: null
            }
        },
        methods: {

            // 初始化页面查询数据
            initPage() {

                this.unSub();
                // 重置数据为默认值
                Object.assign(this.$data, this.$options.data());
                // 参数未回来之前禁用
                this.disableBtn = true;
                websocket.initWebSocket(this.topic, this.wsCallback);

                let param = {

                    cmdCode: '0835',
                    deviceNumber: this.deviceNumber,
                    deviceWholeId: this.deviceId,
                    assemblyType: 3
                };
                deviceOpStatusOperate({message: JSON.stringify(param)}).then((res) => {

                    if (res.code === '100') {

                        this.cmdLoading = loading.loading("策略状态读取中...", 60);
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err => console.error(err));
            },
            // ws回调方法
            wsCallback(msg) {

                if (this.cmdLoading) {

                    this.cmdLoading.close();
                }
                let data = JSON.parse(msg.body);
                let code = data.instructionCode;
                // 指令码为0x0935 = 2357 读取参数响应
                if (code == "2357") {

                    message.success("策略状态读取成功");
                    // 渲染页面数据
                    let result = data.messageBody.devOpsStatus;
                    let batStatusList = result.statusList;
                    for (let i = 0; i < batStatusList.length; i++) {

                        if (batStatusList[i] == 1) {

                            this.enableArr.oldValue.push((i + 1) + "");
                            this.enableArr.newValue.push((i + 1) + "");
                        }
                    }
                    this.disableBtn = false;
                }
                // 指令码为0x0936 = 2358 设置状态响应成功则需要将当前设置成功的值放入oldValue中
                // 防止在页面上进行连续修改时,提示不正确的问题
                if (code == "2358") {

                    message.success("策略状态设置成功");
                    this.enableArr.oldValue = this.enableArr.newValue;
                }
            },
            // 发送设置参数指令
            sendCmd() {

                let tNewValue = this.enableArr.newValue;
                let tOldValue = this.enableArr.oldValue;
                if (tNewValue.length == tOldValue.length) {

                    tNewValue.sort();
                    tOldValue.sort();
                    if (JSON.stringify(tNewValue) == JSON.stringify(tOldValue)) {

                        message.warning('内容未发生变化，无需提交')
                        return;
                    }
                }
                this.$refs["strategyForm"].validate((valid) => {

                    if (valid) {

                        let statusArr = [];
                        for (let i = 1; i <= 3; i++) {

                            if (tNewValue.indexOf(i + "") > -1) {

                                statusArr[i-1] = 1;
                            } else {

                                statusArr[i-1] = 0;
                            }
                        }
                        let param = {

                            cmdCode: '0836',
                            deviceNumber: this.deviceNumber,
                            deviceWholeId: this.deviceId,
                            assemblyType: 3,
                            statusArr: statusArr.reverse(),
                            remark: this.form.remark
                        };
                        deviceOpStatusOperate({message: JSON.stringify(param)}).then((res) => {

                            if (res.code === '100') {

                                this.cmdLoading = loading.loading("策略状态下发中...", 60);
                            } else {

                                message.error(res.msg);
                            }
                        }).catch(err => console.error(err));
                    }
                });
            },
            unSub() {

                websocket.unSubTopic(this.topic);
            }
        }
    }
</script>
<style scoped="scoped">

    div::-webkit-scrollbar {

        width: 10px;
    }
    .deviceStrategySetClass {

        width: 1150px;
        height: 500px;
        overflow-y: auto;
    }
    .remark {

        width: 780px;
    }
</style>
